<template>
    <div
        class="scroll-mt-34 border-b border-gray-200"
        :data-slot="slot.id"
    >
        <button
            :class="[
                'relative flex w-full items-stretch justify-start gap-6 border-2 bg-gray-50 text-left transition',
                isSelected ? 'border-gray-950' : 'border-transparent',
                !isDisabled && 'hover:bg-gray-100',
            ]"

            :disabled="isDisabled"
            @click="emit('select')"
        >
            <div class="flex flex-col items-center justify-center gap-1.5 py-3 pl-3 font-medium">
                <AspectRadio
                    :model-value="activeSlot?.id"
                    :disabled="isDisabled"
                    :value="slot.id"
                    class="mt-1.5 size-5"
                    name="selected-slot"
                    color="black"
                />

                <time class="text-sm">
                    {{ format(slot.dateTime, 'HH:mm') }}
                </time>

                <AspectBadge
                    :color="isDisabled ? 'gray' : 'green'"
                    :class="[
                        'self-end text-2xs',
                        'flex xl:hidden'
                    ]"
                >
                    {{ formatCurrency(slot.minPrice || 0) }}
                </AspectBadge>
            </div>

            <div
                :class="[
                    'flex-col items-center justify-center',
                    'hidden xl:flex',
                ]"
            >
                <AspectBadge
                    class="text-2xs"
                    :color="isDisabled ? 'gray' : 'green'"
                >
                    {{ formatCurrency(slot.minPrice || 0) }}
                </AspectBadge>
            </div>

            <ReservationSlotPickerEntries
                :slot="slot"
                :is-restricted="isRestricted"
            />

            <div
                v-if="isRestricted"
                class="group/restriction absolute inset-0 flex cursor-not-allowed items-center justify-center bg-gray-100/40 p-3 "
            >
                <div
                    :class="[
                        'flex items-center rounded-full bg-white shadow transition',
                        'opacity-0 group-hover/restriction:opacity-100',
                    ]"
                >
                    <figure class="shrink-0 rounded-full bg-gray-200 p-1.5">
                        <AspectIcon name="padlock-square-1" class="size-6 text-gray-600" />
                    </figure>

                    <div class="px-3 py-1.5 text-sm">
                        <template v-if="slot.restrictions?.pastRestricted">
                            {{ t('Time slot is in the past') }}
                        </template>
                        <template v-else-if="slot.restrictions?.stateRestricted">
                            {{ t('This time slot is not available') }}
                        </template>
                        <template v-else-if="slot.restrictions?.lockedRestricted">
                            {{ t('Reservation in progress...') }}
                        </template>
                        <template v-else-if="slot.restrictions?.creditRestricted">
                            {{ t('You have no credits left') }}
                        </template>
                        <template v-else-if="slot.restrictions?.dailyLimitRestricted">
                            {{ t('You already have a reservation on that day') }}
                        </template>
                        <template v-else-if="slot.restrictions?.weeklyLimitRestricted">
                            {{ t("You've reached your membership's weekly limit") }}
                        </template>
                        <template v-else-if="slot.restrictions?.timeRestricted">
                            {{ t("You're membership does not allow to reserve this time slot") }}
                        </template>
                        <template v-else>
                            {{ t("This time slot is not available") }}
                        </template>
                    </div>
                </div>
            </div>
        </button>
    </div>
</template>

<script lang="ts" setup>
    import { t } from '@aspect/shared/plugins/i18n.ts';
    import { format } from '@aspect/shared/utils/date.ts';
    import { formatCurrency } from '@aspect/shared/utils/number.ts';

    import AspectBadge from '@aspect/shared/components/aspect-badge.vue';
    import AspectRadio from '@aspect/shared/components/aspect-radio.vue';
    import AspectIcon from '@aspect/shared/components/aspect-icon.vue';

    import ReservationSlotPickerEntries from '@/consumer/components/reservation/reservation-slot-picker-entries.vue';

    import type { SlotData } from '@aspect/shared/types/generated';

    // PROPS & EMIT
    const props = defineProps<{
        slot: SlotData;
        activeSlot: SlotData | null;
    }>();
    const emit = defineEmits<{
        select: [];
    }>();

    const isSelected = computed(() => {
        return props.activeSlot?.id === props.slot.id;
    });

    const isDisabled = computed(() => {
        return !props.slot.isSelectable || !props.slot.isAvailable;
    });

    const isRestricted = computed(() => {
        return !props.slot.isSelectable;
    });
</script>
