import { useAxiosForm } from '@aspect/shared/composables/use-axios-form.ts';
import { useRoute } from '@/shared/composables/use-route.ts';
import { useTicketOfficeStore } from '@/consumer/stores/use-ticket-office-store.ts';

import type { Ref } from 'vue';
import type { Offering } from '@aspect/shared/types/global';
import type {
    BundleData,
    ConsumerOfferingRequest,
    CreateEntryData,
    DivisionData,
    OfferingSlotData,
    SlotData,
    TicketData,
} from '@aspect/shared/types/generated';

export function useReservationOfferings({ division, selectedSlot }: {
    division: Ref<DivisionData>,
    selectedSlot: Ref<SlotData | null>,
}) {
    const { consumerRoute } = useRoute();
    const store = useTicketOfficeStore();


    // ENTRIES
    const entries = ref<CreateEntryData[]>([]);

    const offeringsSelected = computed(() => {
        return !!entries.value.length;
    });

    watch(() => selectedSlot.value?.id, () => {
        entries.value = [];
        getSlotOfferings();
    });


    // ENTRIES VALIDATION
    function entryIsValid(entry: CreateEntryData, index: number) {
        if (entry.inviteType === 'member') {
            return !!entry.customerId;
        }

        if (entry.entryableType === 'bundle') {
            return (entry.subEntries || []).every((subEntry, subIndex) => entryIsValid(subEntry, subIndex));
        }

        if (!entry.inviteType) {
            return false;
        }

        if (index !== 0 && !store.memberBooking) {
            return true;
        }

        if (entry.inviteType === 'guest') {
            return !!(entry.firstName || '').trim() && !!(entry.lastName || '').trim();
        }

        return false;
    }

    const allEntriesValid = computed(() => {
        return entries.value.every((entry, index) => entryIsValid(entry, index));
    });

    const ticketsCount = computed(() => {
        return entries.value.reduce((total, entry) => {
            return entry.entryableType === 'bundle'
                ? total + (entry.subEntries?.length || 0)
                : total + 1;
        }, 0);
    });


    // SLOT OFFERINGS
    const slotOfferings = ref<OfferingSlotData | null>(null);
    const slotOfferingsLoading = ref(false);

    async function getSlotOfferings() {
        if (!selectedSlot.value) {
            slotOfferings.value = null;
            return;
        }

        slotOfferingsLoading.value = true;

        const form = useForm<ConsumerOfferingRequest>({
            slotId: selectedSlot.value.id,
            customerMembershipId: store.selectedMembership?.id || null,
        });

        const response = await useAxiosForm(form).get(consumerRoute('/{division}/offerings', {
            division: division.value.id,
        }));

        if (response) {
            slotOfferings.value = response.data;
            slotOfferingsLoading.value = false;
        }
    }


    // OFFERINGS
    const offerings = computed<Offering[]>(() => {
        if (!selectedSlot.value || !slotOfferings.value) {
            return [];
        }

        if (selectedSlot.value.id !== slotOfferings.value.slotId) {
            return [];
        }

        const slot = selectedSlot.value as SlotData;
        const slotCapacity = getSlotCapacity(slot);

        const ticketOfferings: Offering[] = slotOfferings.value.tickets.map((ticket: TicketData) => {
            const periodTicket = ticket.periodTickets?.find(periodTicket => periodTicket.periodId === slot.periodId);

            return {
                ...ticket,
                _type: 'ticket',
                price: periodTicket?.adjustedPrice ?? ticket.price,
                slotId: slot.id,
                slotCapacity,
                limit: getRemainingCapacity(slot, ticket),
            };
        });

        const bundleOfferings: Offering[] = slotOfferings.value.bundles.map((bundle: BundleData) => {
            const remainingCapacity = getRemainingCapacity(slot, bundle);
            const ticketCount = (bundle.tickets ?? []).reduce((total, ticket) => {
                return total + ticket.quantity;
            }, 0);

            return {
                ...bundle,
                _type: 'bundle',
                slotId: slot.id,
                slotCapacity,
                limit: ticketCount > 0 && remainingCapacity !== null
                    ? Math.floor(remainingCapacity / ticketCount)
                    : null,
            };
        });

        return [
            ...ticketOfferings,
            ...bundleOfferings,
        ].filter(offering => offering.slotCapacity === null || offering.slotCapacity > 0);
    });


    // REMAINING CAPACITY
    function getSlotCapacity(slot: SlotData) {
        return slot.restrictions?.limitedCapacity || slot.remainingCapacity || null;
    }

    function getRemainingCapacity(slot: SlotData, offering: TicketData | BundleData): number | null {
        const slotCapacity = getSlotCapacity(slot);

        if (slotCapacity === null) {
            return null;
        }

        const selectedCapacity = entries.value.reduce((total, entry) => {
            // Exclude entries for the current offering from the count
            if (entry.entryableId === offering.id) {
                return total;
            }

            const quantity = entry.entryableType === 'bundle'
                ? entry.subEntries?.length ?? 0
                : 1;

            return total + quantity;
        }, 0);

        const remainingCapacity = slotCapacity - selectedCapacity;

        return remainingCapacity > 0 ? remainingCapacity : 0;
    }

    return {
        slotOfferings,
        slotOfferingsLoading,

        offerings,
        offeringsSelected,

        entries,
        ticketsCount,
        allEntriesValid,
    };
}
