<template>
    <div class="rounded shadow-lg">
        <div class="relative flex items-stretch justify-between rounded-t bg-white last:rounded-b">
            <div class="absolute inset-x-0 top-0 -mt-2 flex justify-center">
                <AspectBadge v-if="showPrivate" color="blue">
                    {{ t('Private') }}
                </AspectBadge>
            </div>

            <div class="flex grow items-center justify-between gap-4 p-4">
                <div class="flex flex-col">
                    <h4 class="line-clamp-3 text-base font-normal md:text-xl">
                        {{ name }}
                    </h4>
                </div>

                <div class="flex shrink-0 flex-col items-center gap-2 sm:flex-row">
                    <div class="flex shrink-0 items-center text-base font-normal md:text-xl">
                        {{ formatCurrency(offering.price) }}
                    </div>

                    <AspectInput
                        :max="offering.limit"
                        :min="0"
                        :model-value="quantity"
                        class="number-spin-none w-8 shrink-0 px-0 text-center"
                        type="number"
                        @update:model-value="value => setQuantity(toInteger(value))"
                    />
                </div>
            </div>

            <div class="isolate flex shrink-0 flex-col">
                <ReservationOfferingListItemButton
                    :class="[
                        'rounded-tr border-b border-l border-gray-200',
                        'hover:z-10',
                    ]"
                    :disabled="offering.limit !== null && quantity >= offering.limit"
                    :disabled-reason="t('Ticket limit reached')"
                    @click="add(1)"
                >
                    <ChevronUpIcon class="size-5" />
                </ReservationOfferingListItemButton>

                <ReservationOfferingListItemButton
                    :class="[
                        'border-l border-gray-200',
                        !quantity && !description && 'rounded-br',
                    ]"
                    :disabled="quantity <= 0"
                    @click="remove(1)"
                >
                    <ChevronDownIcon class="size-5" />
                </ReservationOfferingListItemButton>
            </div>
        </div>


        <div
            v-if="description"
            class="border-t border-gray-200 bg-white p-2 text-left text-xs text-gray-600 last:rounded-b md:text-justify"
        >
            {{ description }}
        </div>

        <div
            v-if="quantity && showEntries"
            class="grid grid-cols-1 gap-4 rounded-b border-t border-gray-200 bg-gray-50 p-2 md:grid-cols-2 md:p-4"
        >
            <ReservationOfferingListItemEntry
                v-for="(entry, index) in filteredEntries"
                :key="index"
                :count="filteredEntries.length"
                :entry="entry"
                :entries="entries"
                :index="index"
                :number="index + 1"
                :can-invite-members="canInviteMembers"
                :can-invite-guests="canInviteGuests"
                :guests-maximum="guestsMaximum"
                :selected-slot-entries="selectedSlotEntries"
                :selected-slot="selectedSlot"
                @update="(value: object) => updateEntry(index, value)"
            />
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/20/solid';
    import { cloneDeep, toInteger } from 'lodash-es';

    import { formatCurrency } from '@aspect/shared/utils/number.ts';
    import { getTranslatedValue, t } from '@aspect/shared/plugins/i18n.ts';

    import { usePageProps } from '@/shared/composables/use-page-props.ts';
    import { useTicketOfficeStore } from '@/consumer/stores/use-ticket-office-store.ts';

    import AspectInput from '@aspect/shared/components/aspect-input.vue';
    import AspectBadge from '@aspect/shared/components/aspect-badge.vue';

    import ReservationOfferingListItemButton from './reservation-offering-list-item-button.vue';
    import ReservationOfferingListItemEntry from './reservation-offering-list-item-entry.vue';

    import type { CreateEntryData, BundleData, SlotData, EntryData } from '@aspect/shared/types/generated';
    import type { Offering } from '@aspect/shared/types/global';

    // PROPS & EMIT
    const props = withDefaults(defineProps<{
        offering: Offering;
        offeringType?: 'ticket' | 'bundle';
        selectedSlot: SlotData;
        canInviteMembers: boolean;
        canInviteGuests: boolean;
        guestsMaximum: number | null;
    }>(), {
        offeringType: 'ticket',
    });

    const pageProps = usePageProps();
    const store = useTicketOfficeStore();

    const entries = defineModel<CreateEntryData[]>('entries', { required: true });

    function entryIsSameOffering(entry: CreateEntryData) {
        const isSameOffering = entry.entryableId === props.offering.id;
        const isSameType = entry.entryableType === props.offeringType;
        const isSameSlot = entry.slotId === props.offering.slotId || !props.offering.slotId;

        return isSameOffering && isSameType && isSameSlot;
    }


    // FILTERED ENTRIES
    const filteredEntries = computed(() => {
        return entries.value.filter(entryIsSameOffering);
    });

    function updateEntry(index: number, value: object) {
        const updatedEntries = [...filteredEntries.value];
        const otherTicketEntries = entries.value.filter((entry) => !entryIsSameOffering(entry));

        updatedEntries[index] = {
            ...updatedEntries[index],
            ...value,
        };

        updateEntries([...otherTicketEntries, ...updatedEntries]);
    }


    // SELECTED SLOT ENTRIES
    const selectedSlotEntries = computed(() => {
        return (props.selectedSlot.entries || []).reduce((entries: EntryData[], currentEntry) => {
            if (currentEntry.entryableType === 'bundle') {
                entries.push(...currentEntry.subEntries || []);
            } else {
                entries.push(currentEntry);
            }

            return entries;
        }, []);
    });


    // QUANTITY
    const quantity = computed(() => {
        return filteredEntries.value.length;
    });

    function setQuantity(quantity: number) {
        if (quantity <= 0) {
            quantity = 0;
        }

        if (quantity >= 600) {
            quantity = 600;
        }

        if (props.offering.limit !== null && quantity >= props.offering.limit) {
            quantity = props.offering.limit;
        }

        const existingEntries = entries.value.filter(entryIsSameOffering);
        const quantityToAddOrRemove = quantity - existingEntries.length;

        if (quantityToAddOrRemove > 0) {
            add(quantityToAddOrRemove);
        } else if (quantityToAddOrRemove < 0) {
            remove(quantityToAddOrRemove);
        }
    }


    // ADD
    function add(quantity: number) {
        const quantityToAdd = Math.abs(quantity);
        const newEntries: CreateEntryData[] = [];
        const subEntries: CreateEntryData[] = [];

        if (props.offeringType === 'bundle') {
            (props.offering as BundleData).tickets?.forEach((ticket) => {
                Array.from(Array(ticket.quantity)).forEach(() => {
                    subEntries.push({
                        slotId: props.offering.slotId,
                        entryableId: ticket.ticketId,
                        entryableType: 'ticket',
                        firstName: null,
                        lastName: null,
                        inviteType: !props.canInviteMembers ? 'guest' : null,
                        customerId: null,
                        customerMembershipId: null,
                    });
                });
            });
        }

        Array.from(Array(quantityToAdd)).forEach(() => {
            newEntries.push({
                slotId: props.offering.slotId,
                entryableId: props.offering.id,
                entryableType: props.offeringType,
                firstName: null,
                lastName: null,
                inviteType: !props.canInviteMembers ? 'guest' : null,
                customerId: null,
                customerMembershipId: null,
                subEntries,
            });
        });

        autoSelectMember(newEntries);
        updateEntries([...entries.value, ...newEntries]);
    }

    function autoSelectMember(newEntries: CreateEntryData[]) {
        if (!pageProps.value.member) {
            return;
        }

        const member = pageProps.value.member;
        const memberAlreadySelected = selectedSlotEntries.value.some((entry) => entry.customer?.id === member.id);
        const selectedTickets = entries.value.map(entry => entry.entryableType === 'bundle' ? entry.subEntries : entry).flat();
        const isEventOrFromMemberArea = props.selectedSlot.scheduleType === 'event' || store.memberBooking;

        if (memberAlreadySelected || selectedTickets.length || !isEventOrFromMemberArea) {
            return;
        }

        const firstValidEntry = newEntries.find((entry) => {
            return props.offeringType !== 'bundle' || !!entry.subEntries?.length;
        });

        if (firstValidEntry) {
            if (props.offeringType === 'bundle' && firstValidEntry.subEntries) {
                firstValidEntry.subEntries[0].inviteType = 'member';
                firstValidEntry.subEntries[0].customerId = member.id;
            } else {
                firstValidEntry.inviteType = 'member';
                firstValidEntry.customerId = member.id;
            }
        }
    }


    // REMOVE
    function remove(quantity: number) {
        const quantityToRemove = Math.abs(quantity);
        let removed = 0;

        const updatedEntries = [...entries.value].reverse().filter(entry => {
            if (entryIsSameOffering(entry) && removed < quantityToRemove) {
                removed++;
                return false;
            }

            return true;
        }).reverse();

        // Start from the end of the array and remove offerings with same id
        updateEntries(updatedEntries);
    }

    function updateEntries(updatedEntries: CreateEntryData[]) {
        entries.value = cloneDeep(updatedEntries);
    }


    // SHOW ENTRIES
    const showEntries = computed(() => {
        if (!quantity.value) {
            return false;
        }

        if (!pageProps.value.tenant.settings.ticketingEntryName) {
            return false;
        }

        if (props.offeringType === 'bundle') {
            return filteredEntries.value.some(entry => entry.subEntries?.length);
        }

        return true;
    });


    // SHOW PRIVATE
    const showPrivate = computed(() => {
        return props.offering.availability === 'private';
    });


    // NAME
    const name = computed(() => {
        return getTranslatedValue(props.offering.name);
    });


    // DESCRIPTION
    const description = computed(() => {
        return getTranslatedValue(props.offering.description);
    });
</script>
