import { isArray } from 'lodash-es';
import { usePageProps } from '@/shared/composables/use-page-props.ts';

const appUrl = import.meta.env.VITE_APP_URL;
const siteUrl = appUrl.replace('app.', 'site.');
const cmsUrl = appUrl.replace('app.', 'cms.');
const membersUrl = appUrl.replace('app.', 'members.');
const ticketOfficeUrl = appUrl.replace('app.', 'ticketoffice.');

function parseUrl(path: string, params: Record<string, any> = {}) {
    path = path.replace(/\/$/, '');

    for (const key in params) {
        const pathKey = `{${key}}`;

        if (path.includes(pathKey) || !params[key]) {
            path = path.replace(pathKey, params[key]);
            delete params[key];
        }
    }

    const url = new URL(path);

    for (const key in params) {
        if (isArray(params[key])) {
            params[key].forEach((value: any) => {
                url.searchParams.append(`${key}[]`, value);
            });
        } else {
            url.searchParams.append(key, params[key]);
        }
    }

    return url.href;
}

export const useRoute = () => {
    const pageProps = usePageProps();

    return {
        cmsRoute(path: string, params?: Record<string, any>): string {
            if (!path.startsWith('/')) {
                path = `/${path}`;
            }

            path = `${cmsUrl}${path}`;

            return parseUrl(path, params);
        },
        membersRoute(path: string, params?: Record<string, any>): string {
            const tenantUrl = pageProps.value.tenant.slug ?? pageProps.value.tenant.id;

            if (!path.startsWith('/')) {
                path = `/${path}`;
            }

            path = `${membersUrl}/${tenantUrl}${path}`;

            return parseUrl(path, params);
        },
        ticketOfficeRoute(path: string, params?: Record<string, any>): string {
            const tenantUrl = pageProps.value.tenant.slug ?? pageProps.value.tenant.id;

            if (!path.startsWith('/')) {
                path = `/${path}`;
            }

            path = `${ticketOfficeUrl}/${tenantUrl}${path}`;

            return parseUrl(path, params);
        },
        siteRoute(path: string, params: Record<string, any> = {}): string {
            const rootDomain = window.location.hostname.split('.').slice(-2).join('.');
            const aspectDomain = siteUrl.split('.').slice(-2).join('.');
            const tenantUrl = pageProps.value.tenant.slug ?? pageProps.value.tenant.id;

            if (!path.startsWith('/')) {
                path = `/${path}`;
            }

            if (rootDomain === aspectDomain) {
                path = `${siteUrl}/${tenantUrl}${path}`;
            }

            return parseUrl(path, params);
        },
        consumerRoute(path: string, params: Record<string, any> = {}): string {
            const tenantUrl = pageProps.value.tenant.slug ?? pageProps.value.tenant.id;

            if (!path.startsWith('/')) {
                path = `/${path}`;
            }

            path = `${appUrl}/consumer/${tenantUrl}${path}`;

            return parseUrl(path, params);
        },
    };
};
