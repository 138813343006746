<template>
    <div class="z-10 h-12 border-b border-gray-200 bg-white/70 backdrop-blur-lg">
        <div class="pointer-events-none absolute inset-0 z-20 size-full">
            <figure
                :class="[
                    'absolute inset-y-0 left-0 z-10 max-h-full w-12 shrink-0 bg-gradient-to-r from-white via-white/80 to-white/0 transition',
                    showLeftFade ? 'opacity-100' : 'opacity-0',
                ]"
            />
            <figure
                :class="[
                    'absolute inset-y-0 right-0 z-10 max-h-full w-12 shrink-0 bg-gradient-to-l from-white via-white/80 to-white/0 transition',
                    showRightFade ? 'opacity-100' : 'opacity-0',
                ]"
            />
        </div>

        <div
            ref="scrollContainer"
            class="flex size-full items-center justify-between overflow-auto px-2 lg:justify-around"
            @scroll="onScroll"
        >
            <button
                v-for="timeOfDay in timesOfDay"
                :key="timeOfDay.time"
                :class="[
                    'flex shrink-0 items-center justify-center rounded p-2',
                    timeOfDay.remainingCapacity === 0 ? 'cursor-not-allowed text-gray-300' : 'hover:bg-gray-150',
                ]"
                :disabled="timeOfDay.remainingCapacity === 0"
                @click="scrollTo(timeOfDay.firstSlot)"
            >
                <time class="text-sm font-regular tabular-nums">
                    {{ timeOfDay.time }}
                </time>
            </button>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { date } from '@aspect/shared/utils/date.ts';

    import type { SlotData } from '@aspect/shared/types/generated';

    interface TimeOfDay {
        time: string;
        remainingCapacity: null | number;
        firstSlot: SlotData;
    }

    const props = defineProps<{
        slots: SlotData[];
        checkCapacity: boolean;
    }>();


    // TIMES OF DAY
    const timesOfDay = computed<TimeOfDay[]>(() => {
        return props.slots.reduce((hours: TimeOfDay[], slot: SlotData) => {
            if (!props.checkCapacity) {
                slot.remainingCapacity = null;
            }

            if (slot.remainingCapacity === undefined) {
                return hours;
            }

            const slotTime = date(slot.dateTime).format('HH:00');
            const existingHour = hours.find((hour) => hour.time === slotTime);

            if (existingHour) {
                if (slot.remainingCapacity === null) {
                    existingHour.remainingCapacity = null;
                } else if (existingHour.remainingCapacity !== null) {
                    existingHour.remainingCapacity += slot.remainingCapacity;
                }
            } else {
                hours.push({
                    time: slotTime,
                    remainingCapacity: slot.remainingCapacity,
                    firstSlot: slot,
                });
            }

            return hours;
        }, []);
    });

    // SCROLL FADES
    const scrollContainer = ref<HTMLElement>();
    const scrollPosition = ref(0);

    const showLeftFade = computed(() => {
        return scrollPosition.value > 0;
    });

    const showRightFade = computed(() => {
        if (!scrollContainer.value) {
            return false;
        }

        const { scrollWidth, clientWidth } = scrollContainer.value;

        if (scrollWidth > clientWidth) {
            return scrollPosition.value + clientWidth < scrollWidth;
        }

        return false;
    });

    const onScroll = () => {
        scrollPosition.value = scrollContainer.value ? scrollContainer.value.scrollLeft : 0;
    };


    // SCROLL TO
    function scrollTo(slot: SlotData) {
        const element = document.querySelector(`[data-slot="${slot.id}"]`);

        element?.scrollIntoView({ behavior: 'smooth' });
    }
</script>
