<!-- eslint-disable vue/no-v-html -->
<template>
    <div class="space-y-4">
        <AspectInputGroup v-if="userIsLoggedIn">
            <label class="inline-flex items-center" for="bypassPayment">
                <AspectCheckbox
                    v-model:checked="form.payment.bypass"
                    :error="form.errors['payment.bypass']"
                    name="bypassPayment"
                />
                <span class="ml-2 text-sm text-gray-600">
                    {{ t('Bypass payment') }}
                </span>
            </label>
        </AspectInputGroup>

        <template v-if="!form.payment.bypass">
            <div v-if="pageProps.tenant.hasPromotions" class="space-y-4">
                <AspectInputGroup>
                    <AspectLabel for="promoCode">
                        {{ t('Promo Code') }}
                    </AspectLabel>
                    <AspectInput
                        v-model="promoCodeForm.attemptedCode"
                        :auto-show-error="true"
                        :error="promoCodeForm.errors.codes || form.errors.promoCodes"
                        name="promoCode"
                        @keyup.enter="emit('applyPromoCode')"
                    >
                        <template #after>
                            <AspectButtonAttached
                                :loading="promoCodeForm.processing"
                                class="rounded-r px-2 py-0 text-sm font-normal"
                                color="black"
                                :color-hover-only="false"
                                @click="emit('applyPromoCode')"
                            >
                                {{ t('Apply') }}
                            </AspectButtonAttached>
                        </template>
                    </AspectInput>

                    <div v-if="form.promoCodes.length" class="mt-2 flex flex-wrap items-center gap-2">
                        <AspectBadge v-for="promoCode in form.promoCodes" :key="promoCode" color="gray">
                            {{ promoCode }}
                            <template #right-annex>
                                <button class="-m-1 p-1 transition-colors hover:text-gray-200" @click="removePromoCode(promoCode)">
                                    <XIcon class="size-4" />
                                </button>
                            </template>
                        </AspectBadge>
                    </div>
                </AspectInputGroup>

                <AspectAlert v-if="promoTicketLimit.reached && promoTicketLimit.value" type="info">
                    <template v-if="form.promoCodes.length > 1">
                        {{ tc('The promo codes are limited to :amount ticket.|The promo codes are limited to :amount tickets.', promoTicketLimit.value, { amount: promoTicketLimit.value.toString() }) }}
                    </template>
                    <template v-else>
                        {{ tc('The promo code is limited to :amount ticket.|The promo code is limited to :amount tickets.', promoTicketLimit.value, { amount: promoTicketLimit.value.toString() }) }}
                    </template>
                </AspectAlert>
                <AspectAlert v-if="paymentAmount && paymentAmount.discountAmount" type="info">
                    {{ t('Discount of :amount applied.', { amount: formatCurrency(paymentAmount.discountAmount, paymentAmount.currency) }) }}
                </AspectAlert>
            </div>

            <div v-if="!isFree && paymentAmount" :class="form.payment.method === 'card' ? 'space-y-4' : 'space-y-2'">
                <AspectInputGroup v-if="paymentMethodOptions.length > 1">
                    <AspectLabel for="paymentMethod" :required="true">
                        {{ t('Payment Method') }}
                    </AspectLabel>
                    <AspectSelect
                        v-model="form.payment.method"
                        :error="form.errors['payment.method']"
                        :options="paymentMethodOptions"
                        :sorted="false"
                        name="paymentMethod"
                    />
                </AspectInputGroup>

                <AspectInputGroup v-if="form.payment.method === 'card'">
                    <AspectLabel>
                        {{ t('Card Details') }}
                    </AspectLabel>
                    <div v-if="!cardAttached" class="mb-4 flex justify-center">
                        <AspectSpinner class="size-8" />
                    </div>
                    <div ref="cardContainer" />
                    <AspectAlert v-if="hasSquareError" type="error">
                        {{ t('We are currently experiencing payment processing issues with our provider.') }}
                        {{ t('We apologize for any inconvenience this may cause and kindly request that you try again at a later time.') }}
                    </AspectAlert>
                </AspectInputGroup>

                <AspectAlert v-else-if="form.payment.method === 'cheque'" type="info">
                    <div class="flex flex-col gap-2">
                        <div v-if="pageProps.tenant.chequePayableTo">
                            <div class="font-normal">
                                {{ t('Payable to:') }}
                            </div>
                            <div>
                                {{ pageProps.tenant.chequePayableTo }}
                            </div>
                        </div>
                        <div>
                            <div class="font-normal">
                                {{ t('Posted to:') }}
                            </div>
                            <p v-if="pageProps.tenant.chequePostedTo" v-html="pageProps.tenant.chequePostedTo" />
                            <p v-else>
                                {{ pageProps.tenant.streetAddress }}<br>
                                {{ pageProps.tenant.city }} ({{ pageProps.tenant.province ? t(pageProps.tenant.province) : '' }})<br>
                                {{ pageProps.tenant.postalCode }}
                            </p>
                        </div>
                        <div v-if="pageProps.tenant.chequeAttentionOf">
                            <div class="font-normal">
                                {{ t('To the attention of:') }}
                            </div>
                            <div>
                                {{ pageProps.tenant.chequeAttentionOf }}
                            </div>
                        </div>
                    </div>
                </AspectAlert>

                <AspectAlert v-else-if="form.payment.method === 'interacTransfer'" type="info">
                    {{ t('The transfer informations will be sent by email.') }}
                </AspectAlert>

                <AspectAlert v-else-if="form.payment.method === 'bankTransfer'" type="info">
                    {{ t('The banking information will be sent by email.') }}
                </AspectAlert>

                <AspectAlert v-else-if="form.payment.method === 'cash'" type="info">
                    {{ t('Payment will be made on-site.') }}
                </AspectAlert>
            </div>
        </template>
    </div>

    <div v-if="localizedTerms" :class="(form.payment.method !== 'card' || form.payment.bypass || isFree || !paymentAmount) && 'mt-4'">
        <label class="mb-4 inline-flex items-center">
            <AspectCheckbox
                v-model:checked="form.acceptTermsAndConditions"
                :error="form.errors.acceptTermsAndConditions"
                name="termsAndConditions"
            />
            <span class="ml-2 text-sm text-gray-600">
                {{ t('I agree to the') }}
                <button class="text-tenant-500 underline" @click="termsModal.open = true">
                    {{ t('Terms and Conditions').toLowerCase() }}
                </button>
            </span>
        </label>
    </div>

    <!-- Terms & Conditions modal -->
    <ReservationTermsDialog
        v-if="localizedTerms"
        v-model:open="termsModal.open"
        :terms="localizedTerms"
        @accept="termsModal.onAccept"
    />
</template>

<script lang="ts" setup>
    import { XIcon } from 'lucide-vue-next';
    import { getTranslatedValue, t, tc } from '@aspect/shared/plugins/i18n.ts';
    import { formatCurrency } from '@aspect/shared/utils/number.ts';
    import { useSquare } from '@/consumer/composables/use-square.ts';
    import { usePageProps } from '@/shared/composables/use-page-props.ts';

    import AspectAlert from '@aspect/shared/components/aspect-alert.vue';
    import AspectInputGroup from '@aspect/shared/components/aspect-input-group.vue';
    import AspectCheckbox from '@aspect/shared/components/aspect-checkbox.vue';
    import AspectInput from '@aspect/shared/components/aspect-input.vue';
    import AspectLabel from '@aspect/shared/components/aspect-label.vue';
    import AspectSpinner from '@aspect/shared/components/aspect-spinner.vue';
    import AspectBadge from '@aspect/shared/components/aspect-badge.vue';
    import AspectButtonAttached from '@aspect/shared/components/aspect-button-attached.vue';
    import AspectSelect from '@aspect/shared/components/aspect-select.vue';

    import ReservationTermsDialog from '@/consumer/components/reservation/reservation-terms-dialog.vue';

    import type { InertiaForm } from '@inertiajs/vue3';
    import type {
        AmountData, ConsumerPromotionValidateRequest,
        CreateEntryData,
        CreatePaymentData,
        CreateReservationData,
        PaymentMethodsData,
        TranslatableData,
    } from '@aspect/shared/types/generated';
    import type { SelectOption } from '@aspect/shared/composables/use-select.ts';


    // PROPS & EMIT
    const props = defineProps<{
        entries: CreateEntryData[];
        paymentAmount: AmountData | null;
        terms: TranslatableData;
        offeringsSelected: boolean;
        paymentMethods: PaymentMethodsData;
        isFree: boolean;
        promoTicketLimit: {
            value: number | null;
            reached: boolean;
        };
    }>();
    const emit = defineEmits<{
        submit: [value: CreatePaymentData],
        applyPromoCode: [],
    }>();

    const pageProps = usePageProps();

    const form = defineModel<InertiaForm<CreateReservationData>>('form', { required: true });
    const promoCodeForm = defineModel<InertiaForm<ConsumerPromotionValidateRequest>>('promoCodeForm', { required: true });
    const hasError = defineModel<boolean>('hasError', { required: true });


    // SQUARE
    const cardContainer = ref<HTMLElement>();
    const {
        attachCard,
        detachCard,
        hasSquareError,
        cardAttached,
        init: initSquare,
        destroy: destroySquare
    } = useSquare();

    watch(cardContainer, async () => {
        if (cardContainer.value) {
            attachCard(cardContainer.value);
        } else {
            detachCard();
        }
    });

    watch(hasSquareError, (value) => {
        hasError.value = value;
    });

    onMounted(() => {
        initSquare();
    });

    onBeforeUnmount(() => {
        destroySquare();
    });


    // USER IS LOGGED IN
    const userIsLoggedIn = computed(() => {
        return !!pageProps.value.user?.id;
    });


    // TERMS AND CONDITIONS
    const localizedTerms = computed(() => {
        return getTranslatedValue(props.terms);
    });

    const termsModal = reactive({
        open: false,
        onAccept() {
            form.value.acceptTermsAndConditions = true;
        }
    });


    // PAYMENT METHOD OPTIONS
    const paymentMethodOptions = computed<SelectOption[]>(() => {
        const options: SelectOption[] = [];

        if (props.paymentMethods.card) {
            options.push({ value: 'card', label: t('Credit Card') });
        }

        if (props.paymentMethods.interacTransfer) {
            options.push({ value: 'interacTransfer', label: t('Interac e-Transfer') });
        }

        if (props.paymentMethods.cheque) {
            options.push({ value: 'cheque', label: t('Cheque') });
        }

        if (props.paymentMethods.bankTransfer) {
            options.push({ value: 'bankTransfer', label: t('Bank Transfer') });
        }

        if (props.paymentMethods.cash) {
            options.push({ value: 'cash', label: t('On-Site') });
        }

        return options;
    });

    watch(paymentMethodOptions, (value) => {
        if (value.length === 1) {
            form.value.payment.method = value[0].value;
        }
    }, { immediate: true });


    // REMOVE PROMO CODE
    function removePromoCode(promoCode: string) {
        form.value.promoCodes = form.value.promoCodes.filter((code) => code !== promoCode);
    }
</script>
