import { usePageProps } from '@/shared/composables/use-page-props.ts';


export function useFeatures() {
    const pageProps = usePageProps();

    return {
        active(feature: string, value?: string): boolean {
            if (value === undefined) {
                return !!pageProps.value.features[feature];
            }

            return pageProps.value.features[feature] === value;
        },
        inactive(feature: string, value?: string): boolean {

            return !this.active(feature, value);
        },
    };
}
