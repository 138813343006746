<!-- eslint-disable vue/no-v-html -->
<template>
    <section
        :id="section.section_id"
        :class="[
            'bg-white',
            classes.sectionPadding[globals.styles.spacing.value],
        ]"
    >
        <div
            v-if="section.title"
            :class="[
                'container grid grid-cols-1',
                classes.contentGapHalf[globals.styles.spacing.value],
                classes.contentMarginBottomDouble[globals.styles.spacing.value],
            ]"
        >
            <h3 :class="['text-3xl', classes.textAlignment[section.title_alignment.value]]">
                {{ section.title }}
            </h3>
            <h4 v-if="section.sub_title" :class="['text-xl sm:text-2xl', classes.textAlignment[section.title_alignment.value]]">
                {{ section.sub_title }}
            </h4>
        </div>

        <div
            :class="[
                'container grid grid-cols-6 items-center',
                classes.sectionGap[globals.styles.spacing.value],
            ]"
        >
            <figure
                v-if="section.image_side.value == 'left'"
                :class="[
                    imageColumnClasses[section.layout.value],
                    classes.rounding[globals.styles.rounding.value],
                    section.frame && classes.imageFramed,
                ]"
            >
                <img
                    v-if="section.image"
                    :alt="section.image.alt"
                    :class="[
                        'size-full object-cover',
                        classes.rounding[globals.styles.rounding.value],
                        classes.imagePosition[section.image_position.value],
                        classes.imageAspectRatio[section.image_aspect_ratio.value],
                    ]"
                    :height="section.image.height"
                    :src="section.image.url"
                    :width="section.image.width"
                >
            </figure>

            <div :class="textColumnClasses[section.layout.value]">
                <div
                    :class="[
                        'flex flex-col items-start',
                        classes.contentGap[globals.styles.spacing.value],
                        section.text_alignment.value.endsWith('left') && 'items-start',
                        section.text_alignment.value.endsWith('center') && 'items-center',
                        section.text_alignment.value.endsWith('right') && 'items-end',
                    ]"
                >
                    <div
                        :class="[
                            'prose prose-neutral max-w-full prose-p:my-0.5 prose-li:my-0.5',
                            classes.textAlignment[section.text_alignment.value],
                        ]"
                        v-html="section.content"
                    />

                    <div v-if="section.button">
                        <SiteButton
                            :href="linkTypeHref(section)"
                            :is-entry="section.button_link?.startsWith('/')"
                            :target="section.button_target ? '_blank' : '_self'"
                        >
                            {{ section.button_text }}
                        </SiteButton>
                    </div>
                </div>
            </div>

            <figure
                v-if="section.image_side.value == 'right'"
                :class="[
                    imageColumnClasses[section.layout.value],
                    classes.rounding[globals.styles.rounding.value],
                    section.frame && classes.imageFramed,
                    'order-first lg:order-none',
                ]"
            >
                <img
                    v-if="section.image"
                    :alt="section.image.alt"
                    :class="[
                        'size-full object-cover',
                        classes.rounding[globals.styles.rounding.value],
                        classes.imagePosition[section.image_position.value],
                        classes.imageAspectRatio[section.image_aspect_ratio.value],
                    ]"
                    :height="section.image.height"
                    :src="section.image.url"
                    :width="section.image.width"
                >
            </figure>
        </div>
    </section>
</template>

<script lang="ts" setup>
    import { useSite } from '@/site/composables/use-site.ts';

    import SiteButton from '@/site/components/site-button.vue';

    defineProps<{
        section: any;
    }>();

    const { classes, globals } = useSite();

    const imageColumnClasses = {
        large_image: 'col-span-full lg:col-span-4',
        small_image: 'col-span-full lg:col-span-2',
        equal_image: 'col-span-full lg:col-span-3',
    };

    const textColumnClasses = {
        large_image: 'col-span-full lg:col-span-2',
        small_image: 'col-span-full lg:col-span-4',
        equal_image: 'col-span-full lg:col-span-3',
    };

    function linkTypeHref(section) {
        // Hides link if button is disabled
        if (section.button_disabled) {
            return;
        }

        // Files
        if (section.button_link_type.value === 'file') {
            return section.button_file.url;
        }

        // Email
        if (section.button_link_type.value === 'email' && section.button_email_address) {
            return section.button_full_name ? `mailto:${section.button_full_name}<${section.button_email_address}>` : `mailto:${section.button_email_address}`;
        }

        // Phone
        if (section.button_link_type.value === 'phone' && section.button_phone_number) {
            return `tel:${section.button_phone_number}`;
        }

        // Webpages
        if (section.button_link_type.value === 'webpage') {
            return `${section.button_link || ''}${section.button_fragment_identifier ? `#${section.button_fragment_identifier}` : ''}`;
        }
    }
</script>
