<!-- eslint-disable vue/no-v-html -->
<template>
    <section
        :id="section.section_id"
        :class="[
            'bg-white',
            classes.sectionPadding[globals.styles.spacing.value],
        ]"
    >
        <div
            v-if="section.title"
            :class="[
                'container grid grid-cols-1',
                section.title_alignment.value == 'left' && 'justify-content-start',
                section.title_alignment.value == 'center' && 'justify-content-center',
                section.title_alignment.value == 'right' && 'justify-content-end',
                classes.contentMarginBottomDouble[globals.styles.spacing.value],
            ]"
        >
            <div
                :class="[
                    'grid grid-cols-1',
                    section.text_container ? 'mx-auto w-full max-w-prose' : 'max-w-full',
                    classes.contentGapHalf[globals.styles.spacing.value],
                ]"
            >
                <h3 :class="['text-3xl', classes.textAlignment[section.title_alignment.value]]">
                    {{ section.title }}
                </h3>
                <h4 v-if="section.sub_title" :class="['text-xl sm:text-2xl', classes.textAlignment[section.title_alignment.value]]">
                    {{ section.sub_title }}
                </h4>
            </div>
        </div>

        <div :class="['container', section.text_container && 'flex justify-center']">
            <div
                :class="[
                    'flex flex-col',
                    classes.contentGap[globals.styles.spacing.value],
                    section.text_alignment.value.endsWith('left') && 'items-start',
                    section.text_alignment.value.endsWith('center') && 'items-center',
                    section.text_alignment.value.endsWith('right') && 'items-end',
                ]"
            >
                <div
                    :class="[
                        'prose prose-neutral prose-p:my-0.5 prose-li:my-0.5',
                        section.text_container ? 'max-w-prose' : 'max-w-full',
                        classes.textAlignment[section.text_alignment.value],
                    ]"
                    v-html="section.content"
                />

                <div v-if="section.button">
                    <SiteButton
                        :href="linkTypeHref(section)"
                        :is-entry="section.button_link?.startsWith('/')"
                        :target="section.button_target ? '_blank' : '_self'"
                    >
                        {{ section.button_text }}
                    </SiteButton>
                </div>
            </div>
        </div>
    </section>
</template>

<script lang="ts" setup>
    import { useSite } from '@/site/composables/use-site.ts';

    import SiteButton from '@/site/components/site-button.vue';

    defineProps<{
        section: any;
    }>();

    const { classes, globals } = useSite();

    function linkTypeHref(section) {
        // Hides link if button is disabled
        if (section.button_disabled) {
            return;
        }

        // Files
        if (section.button_link_type.value === 'file') {
            return section.button_file.url;
        }

        // Email
        if (section.button_link_type.value === 'email' && section.button_email_address) {
            return section.button_full_name ? `mailto:${section.button_full_name}<${section.button_email_address}>` : `mailto:${section.button_email_address}`;
        }

        // Phone
        if (section.button_link_type.value === 'phone' && section.button_phone_number) {
            return `tel:${section.button_phone_number}`;
        }

        // Webpages
        if (section.button_link_type.value === 'webpage') {
            return `${section.button_link || ''}${section.button_fragment_identifier ? `#${section.button_fragment_identifier}` : ''}`;
        }
    }
</script>
