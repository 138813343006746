import { currentLocaleTag } from '@aspect/shared/plugins/i18n.ts';
import theme, { remToPx } from '@/shared/utils/tailwind.ts';
import { usePageProps } from '@/shared/composables/use-page-props.ts';

import type { Card, Payments, TokenResult } from '@square/web-payments-sdk-types';

let card: Card | null = null;

// TODO: Support Apple Pay
// TODO: Support Google Pay
export function useSquare() {
    const pageProps = usePageProps();

    const config = pageProps.value.square;

    const payments = ref<Payments | null>(null);
    const cardAttached = ref(false);
    const initialized = ref(false);
    const waitingToBeAttached = ref<HTMLElement>();
    const hasSquareError = ref(false);

    watch(initialized, () => {
        if (initialized.value && waitingToBeAttached.value) {
            attachCard(waitingToBeAttached.value);
            waitingToBeAttached.value = undefined;
        }
    });

    watch(currentLocaleTag, () => {
        setLocale();
    });

    function setLocale() {
        if (!payments.value) {
            return;
        }

        payments.value.setLocale(currentLocaleTag.value);
    }

    async function init() {
        if (!window.Square || !config) {
            throw new Error('Square SDK not loaded.');
        }

        payments.value = window.Square.payments(config.applicationId, config.locationId ?? undefined);

        try {
            setLocale();

            // Styles are based on our AspectInput component.
            card = await payments.value.card({
                style: {
                    '.input-container': {
                        borderColor: theme.colors.gray[200],
                        borderRadius: remToPx(theme.borderRadius.sm) as string,
                    },
                    '.input-container.is-focus': {
                        borderColor: theme.colors.gray[950],
                        borderWidth: theme.borderWidth[2],
                    },
                    '.input-container.is-error': {
                        borderColor: theme.colors.red[500],
                        borderWidth: theme.borderWidth[2],
                    },
                    '.message-icon': {
                        color: theme.colors.gray[500],
                    },
                    '.message-icon.is-error': {
                        color: theme.colors.red[500],
                    },
                    '.message-text': {
                        color: theme.colors.gray[600],
                    },
                    '.message-text.is-error': {
                        color: theme.colors.red[500],
                    },
                    'input': {
                        color: theme.colors.gray[900],
                        fontSize: '14px',
                    },
                    'input.is-error': {
                        color: theme.colors.red[500],
                    },
                    'input::placeholder': {
                        color: theme.colors.gray[500],
                    },
                    'input.is-focus::placeholder': {
                        color: theme.colors.gray[500],
                    },
                    'input.is-error::placeholder': {
                        color: theme.colors.gray[500],
                    },
                }
            });
        } catch (e) {
            hasSquareError.value = true;
            throw e;
        }

        requestAnimationFrame(() => {
            initialized.value = true;
        });
    }

    function destroy() {
        if (card) {
            card.destroy();
            card = null;
        }
    }

    async function attachCard(container: HTMLElement | undefined) {
        if (!initialized.value) {
            waitingToBeAttached.value = container;
            return;
        }

        if (!card) {
            throw new Error('Card not initialized.');
        }

        if (!container) {
            throw new Error('Container not found.');
        }

        await card.attach(container);
        cardAttached.value = true;
    }

    async function detachCard() {
        if (!card) {
            throw new Error('Card not initialized.');
        }

        if (!cardAttached.value) {
            return;
        }

        await card.detach();
    }

    async function tokenizeCard(): Promise<TokenResult> {
        if (!card) {
            throw new Error('Card not initialized.');
        }

        return await card.tokenize();
    }

    return {
        cardAttached,
        init,
        destroy,
        attachCard,
        detachCard,
        tokenizeCard,
        hasSquareError,
    };
}
