import { defineStore } from 'pinia';
import type { MemberMembershipData } from '@aspect/shared/types/generated';

export const useTicketOfficeStore = defineStore('ticketOffice', () => {
    const memberBooking = ref(false);
    const selectedMembership = ref<MemberMembershipData | null>(null);

    return {
        memberBooking,
        selectedMembership,
    };
});
