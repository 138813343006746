<!-- eslint-disable vue/no-v-html -->
<template>
    <section
        :id="section.section_id"
        :class="['bg-white', classes.sectionPadding[globals.styles.spacing.value]]"
    >
        <div
            v-if="section.title"
            :class="[
                'container grid grid-cols-1',
                classes.contentGapHalf[globals.styles.spacing.value],
                classes.contentMarginBottomDouble[globals.styles.spacing.value],
            ]"
        >
            <h3 :class="['text-3xl', classes.textAlignment[section.title_alignment.value]]">
                {{ section.title }}
            </h3>
            <h4 v-if="section.sub_title" :class="['text-xl sm:text-2xl', classes.textAlignment[section.title_alignment.value]]">
                {{ section.sub_title }}
            </h4>
        </div>

        <div
            :class="[
                'container grid items-stretch',
                classes.layoutGrid[section.column_quantity.value],
                classes.contentGapDouble[globals.styles.spacing.value],
            ]"
        >
            <div
                v-for="card in section.cards"
                :key="card.id"
                :class="[
                    'flex flex-col',
                    classes.contentGapHalf[globals.styles.spacing.value]
                ]"
            >
                <figure
                    v-if="card.image"
                    :class="[
                        classes.rounding[globals.styles.rounding.value],
                        card.frame && classes.imageFramed,
                    ]"
                >
                    <img
                        :alt="card.image.alt"
                        :class="[
                            'size-full border border-gray-100 object-cover',
                            classes.rounding[globals.styles.rounding.value],
                            classes.imagePosition[card.image_position.value],
                            classes.imageAspectRatio[card.image_aspect_ratio.value],
                        ]"
                        :height="card.image.height"
                        :src="card.image.url"
                        :width="card.image.width"
                    >
                </figure>

                <div
                    :class="[
                        'flex flex-1 flex-col justify-between',
                        classes.contentGap[globals.styles.spacing.value]
                    ]"
                >
                    <div :class="classes.contentGap[globals.styles.spacing.value]">
                        <div :class="classes.textAlignment[card.text_alignment.value]">
                            <label class="text-xs font-bold uppercase tracking-wide text-gray-600">
                                {{ card.label }}
                            </label>
                        </div>
                        <h4 :class="['text-2xl', classes.textAlignment[card.text_alignment.value]]">
                            {{ card.title }}
                        </h4>
                        <div
                            :class="[
                                'prose prose-neutral max-w-full prose-p:my-0.5 prose-li:my-0.5',
                                classes.textAlignment[card.text_alignment.value]]
                            "
                            v-html="card.content"
                        />
                    </div>

                    <div v-if="card.button" class="flex">
                        <SiteButton
                            :disabled="card.button_disabled"
                            :href="linkTypeHref(card)"
                            :is-entry="card.button_link?.startsWith('/')"
                            :target="card.button_target ? '_blank' : '_self'"
                            :text="card.button_text"
                            class="grow"
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script lang="ts" setup>
    import { useSite } from '@/site/composables/use-site.ts';
    import SiteButton from '@/site/components/site-button.vue';

    defineProps<{
        section: any;
    }>();

    const { classes, globals } = useSite();

    const linkTypeHref = (card) => {
        // Hides link if button is disabled
        if (card.button_disabled) {
            return;
        }

        // Files
        if (card.button_link_type.value === 'file') {
            return card.button_file.url;
        }

        // Email
        if (card.button_link_type.value === 'email' && card.button_email_address) {
            return card.button_full_name ? `mailto:${card.button_full_name}<${card.button_email_address}>` : `mailto:${card.button_email_address}`;
        }

        // Phone
        if (card.button_link_type.value === 'phone' && card.button_phone_number) {
            return `tel:${card.button_phone_number}`;
        }

        // Webpages
        if (card.button_link_type.value === 'webpage') {
            return `${card.button_link || ''}${card.button_fragment_identifier ? `#${card.button_fragment_identifier}` : ''}`;
        }
    };
</script>
