import { debounce } from 'lodash-es';

import { useMemberships } from '@/shared/composables/use-memberships.ts';

import type { Ref } from 'vue';
import type { SelectOption } from '@aspect/shared/composables/use-select.ts';
import type {
    CreateEntryData,
    EntryData,
    MemberData,
    MemberMembershipData,
    SlotData,
} from '@aspect/shared/types/generated';

export interface MemberOptionValue {
    member: MemberData;
    membership: MemberMembershipData | null;
}

export interface MemberOption extends SelectOption {
    searchKey?: string;
    value: MemberOptionValue;
}

interface Props {
    entries: CreateEntryData[];
    selectedSlotEntries: EntryData[];
    [key: string]: any;
}

export function useMemberSelect(
    getMembers: (search: string, slot: SlotData) => Promise<MemberData[]>,
    membersLoading: Ref<boolean>,
    slot: Ref<SlotData>,
    requiresMembership: boolean,
    props?: Props,
) {
    const { getMemberNumber } = useMemberships();

    const members = ref<MemberData[]>([]);


    // MEMBER SEARCH
    const memberSearch = ref('');

    const triggerSearch = debounce(() => {
        searchMembers();
    }, 300);

    watch(memberSearch, () => {
        membersLoading.value = true;
        triggerSearch();
    });

    async function searchMembers() {
        members.value = await getMembers(memberSearch.value, slot.value);
    }


    // MEMBER OPTIONS
    function memberIsDisabled({ member, membership }: MemberOptionValue) {
        if (!props) {
            return false;
        }

        const selectedInEntry = (entry: CreateEntryData) => {
            return entry.customerId === member.id || props.selectedSlotEntries.some(entry => {
                return entry.customer?.id === member.id;
            });
        };

        const alreadySelected = props.entries.some((entry) => {
            if (entry.entryableType === 'bundle') {
                return (entry.subEntries || []).some(subEntry => selectedInEntry(subEntry));
            }

            return selectedInEntry(entry);
        });

        if (alreadySelected) {
            return true;
        }

        if (!requiresMembership) {
            return false;
        }

        const selectableMembership = member.selectableMemberships?.find(selectableMembership => selectableMembership.id === membership?.id);

        return !selectableMembership;
    }

    const memberOptions = computed<MemberOption[]>(() => {
        return members.value.reduce((options: MemberOption[], member) => {
            if (requiresMembership) {
                // We duplicate the member for each membership
                member.memberships.forEach(membership => {
                    options.push({
                        label: member.name,
                        subLabel: getMemberNumber(member, membership),
                        disabled: memberIsDisabled({ member, membership }),
                        searchKey: `${member.id}-${membership.id}`,
                        value: {
                            member,
                            membership,
                        },
                    });
                });
            } else {
                // We take the first membership, just to show the member number
                const membership = member.memberships[0];

                options.push({
                    label: member.name,
                    subLabel: getMemberNumber(member, membership),
                    disabled: memberIsDisabled({ member, membership }),
                    searchKey: member.id,
                    value: {
                        member,
                        membership: null, // We don't want to pass a membership if it's not required
                    },
                });
            }

            return options;
        }, []);
    });


    // SELECTED MEMBER OPTION
    const selectedMemberOption = ref<MemberOptionValue>();

    function onSelectMember(value: MemberOptionValue) {
        if (!value) {
            selectedMemberOption.value = undefined;
            return;
        }

        if (memberIsDisabled(value)) {
            return;
        }

        selectedMemberOption.value = value;
    }

    return {
        members,
        memberSearch,
        memberOptions,
        selectedMemberOption,
        onSelectMember,
    };
}
